<template>
  <div class="carne">
       <div class="col-md-12 text-right mt-3 noprint">
             <button type="button" class="btn btn-primary " @click="back">
               <i class="fas fa-arrow-circle-left"></i>
                Voltar
             </button>
             <button type="button" class="btn btn-primary ml-2" @click="print">
               <i class="fas fa-print"></i>
               Imprimir
            </button>
       </div>  
      
      <div class="col-md-12 text-center">
        <h1 class="mb-3">Previsão de Fatura</h1>
        <h3>Cliente: {{preview.holderName}}</h3>
        <h4>Mês/Ano: {{preview.month}}/{{preview.year}}</h4>
        <h4>Vencimento: {{preview.dueDate}}</h4>
        <h4>Prazo Max. de Contestação: {{preview.maxDateAjusts}}</h4>
      </div>  

      <h2 class="col-md-12">Resumo de Valores</h2>
      <div class="row col-md-12">
        <div class="col-xl-3 col-md-3 mt-3">
            <stats-card title="Valor Previsto R$"
                        type="gradient-red"
                        :sub-title="preview.amountPreviewFmt"
                        icon="ni ni-money-coins">

              <template slot="footer">
                <span class="text-nowrap">a faturar</span>
              </template>
            </stats-card>
        </div>
        <div class="col-xl-3 col-md-3 mt-3">
            <stats-card title="Titulares"
                        type="gradient-green"
                        :sub-title="preview.totalHolders"
                        icon="ni ni-single-02">
              <template slot="footer">
                <span class="text-nowrap">total de vidas</span>
              </template>
            </stats-card>
        </div>
        <div class="col-xl-3 col-md-3 mt-3">
            <stats-card title="Dependentes"
                        type="gradient-blue"
                        :sub-title="preview.totalDependents"
                        icon="ni ni-single-02">
              <template slot="footer">
                <span class="text-nowrap">total de vidas</span>
              </template>
            </stats-card>
        </div>
      </div>
    <h2 class="col-md-12">Extrato de beneficiários</h2>
    <div class="col-md-12">
        <table class="table table-responsive font-tabela-peq">
          <tbody>
              <tr v-for="life in preview.lifes" :key="life.holder.id">
                  <td>{{life.holder.name}}  <br> (CPF: {{life.holder.cpfCnpjFmt}})</td>
                  <td>    
                    <table class="table table-responsive font-tabela-peq">
                        <thead class="thead">
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Cartão</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="card in life.cards" :key="card.token">
                                <td>
                                    <span v-if="card.isCardOfHolder">{{card.holder.name}}</span>
                                    <span v-else>{{card.dependent.name}}</span>
                                </td>
                                <td>
                                    <span v-if="card.isCardOfHolder">Titular</span>
                                    <span v-else>Dependente</span>
                                </td>
                                <td>
                                    {{card.numberOnCard}}
                                </td>
                                <td>
                                    <span v-show="card.status == 'BLOCKED'"> BLOQUEADO</span>
                                    <span v-show="card.status == 'OK'">ATIVO</span>
                                    <span v-show="card.status == 'CANCELED'">
                                        CANCELADO<br>
                                        (Em {{card.dateCancel}})
                                    </span>
                                    <span v-show="card.status == 'EXPIRED'">EXPIRADO</span>
                                    <span v-show="card.status == 'BLOCKED_BY_FRAUD'">BLOQUEADO POR FRAUDE</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </td>
              </tr>
              <tr v-show="preview.lifes == null">
                  <td>
                      Nenhum beneficiário disponível.
                  </td>
              </tr>
          </tbody>
        </table>
    </div>

  </div>
</template>

<script>
const optionsAlert = {title: 'Mensagem', size: 'sm'}

export default {
  name: 'previsaofatura',
  mounted(){
     this.loadPreview()
  },
  data(){
    return {
        preview : { lifes: [] }
    }
  },
  methods:{
     loadPreview(){
        this.$clubApi.get('/subscription/preview/invoice?token='+this.$route.query.token)
        .then((response) => {
            this.preview = response.data.object
        }) .catch((error) => {
            this.$dialogs.alert(error.response.data.msg, optionsAlert)
        }).finally(() =>{
            NProgress.done() 
        })
    },
    print(){
      window.print()
    },
    back(){
      window.close()
    }
  }
}
</script>

<style scoped>
  @media print {
      .noprint {
        display: none;
      }
  }
</style>